import React, { Fragment } from 'react'
import Link from 'gatsby-link'

import TwoColumns from 'components/twoColumns'
import SectionHeading from 'components/sectionHeading'
import Project from 'components/project'

import Project1Logo from 'img/articles/project1/img1.jpg'

const Project1Link = <Link to="/project1">Read More</Link>
const Project2Link = <Link to="/project2">Read More</Link>

const Work = () => {
  return (
    <TwoColumns
      wide
      leftColumn={<SectionHeading>Projects</SectionHeading>}
      rightColumn={
        <Fragment>
          <Project
            logo={Project1Logo}
            title="Virtual Reality Simulator"
            abstract="The Dave and Buster's X5 Virtual Reality Simulator is an immersive attraction that transports visitors into the thrilling world of Jurassic World, offering a captivating adventure powered by expertly crafted Unity software, meticulous electrical engineering, and efficient project management. Prepare for an unforgettable experience as you embark on a virtual journey filled with stunning visuals, realistic interactions, and heart-pounding action."
            link={Project1Link}
          />
          <Project
            logo={Project1Logo}
            title="Rock 'em Sock 'em Robots"
            abstract="The LifeSize Rock 'Em Sock 'Em Robots project at Dave and Buster's is a thrilling attraction that brings the classic game to life on a grand scale, combining meticulous mechanical design, precise electrical engineering, and effective project management. Experience the excitement of larger-than-life robotic battles as players step into the ring and unleash their competitive spirit."
            link={Project2Link}
          />
        </Fragment>
      }
    />
  )
}

export default Work
